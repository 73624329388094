.experience-section {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 50px;
}

#left-panel {
    grid-column: 1;
    grid-row: 1;
}

#right-panel {
    margin-top: 0px;
    grid-column: 2;
    grid-row: 1;
    align-items: center;
}

#experience-label {
    margin-top: 50px;
    margin-bottom: 30px;
}

.chips-container {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 400px;
}

.experience-container {
    display: grid;
    max-width: 1000px;
    margin-left: 100px;
    margin-right: 100px;
    justify-content: center;
}

#awards p {
    text-align: center;
}

@media screen and (max-width: 850px) {
    #left-panel {
        grid-column: 1;
        grid-row: 2;
    }

    #right-panel {
        grid-column: 1;
        grid-row: 1;
    }

    .experience-container {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}

@media screen and (min-width: 850px) {
    #left-panel {
        margin-right: 50px;
    }

    #right-panel {
        margin-left: 50px;
    }
}

#experience {
    min-height: 100vh;
    margin-bottom: 50px;
}

.education-label {
    margin-bottom: 10px;
}

.education-container li {
    margin-bottom: -5px;
}

.education-container ul {
    margin-bottom: 5px;
}

.work-content p {
    margin-bottom: 5px;
}

.work-content {
    width: 100%;
    max-width: 550px;
}

.work-item {
    margin-bottom: 30px;
}

.work-item:last-of-type {
    margin-bottom: 50px;
}

#resume-link {
    font-weight: bold;
    transition: opacity 0.3s;
}

@media (hover: hover) {
    #resume-link:hover {
        opacity: 0.6;
    }
}

#resume-link>svg {
    font-size: 20px;
}

.job-dates {
    opacity: 0.5;
}
