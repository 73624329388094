#page-not-found {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

#go-back {
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
}
